<template>
  <div class="Viewer3D">
    <Loadingscreen/>
    <div id="v3-whitescreen" class="v3-whitescreen"/>
    
    <Sidebar v-if="productData" :activeProductIndex="activeProductIndex" :productData="productData"/>
    
    <div class="v3-viewer3D-canvas-container">
      <img v-if="productData" class="v3-logo" :src="productData.products[activeProductIndex].customLogo ? ('src/assets/3DViewer/products/'+productData.products[activeProductIndex].customLogo) : 'src/assets/interface/logo-small.png'" alt="">
      <Buttons v-if="productData" :playOpenDoorrAnimation="playOpenDoorrAnimation" :switchMesh="switchMesh" :activeProductIndex="activeProductIndex" :productData="productData"/>
      <canvas id="v3-viewer3D-canvas"/>  
    </div>  
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onUnmounted, Ref } from 'vue';

// Components
import Buttons from "@/components/Viewer3D/components/Buttons.vue"
import Sidebar from "@/components/Viewer3D/components/Sidebar.vue"
import Loadingscreen from "@/components/Viewer3D/components/Loadingscreen.vue"

import { Viewer3D } from "@/components/Viewer3D/scripts/Viewer3D";
import { HoshizakiAnalyticsSingelton } from '@/analytics/AnalyticsSingelton';

const props = defineProps({
  productData: {
    type: Object,
    required: true
  }
});

let activeProductIndex = ref(0);
let isDoorOpen:Ref<boolean[]> = ref([]);

let viewer3D = new Viewer3D();
onMounted(() => {
  console.log("[3D Viewer] Mounted");

  let canvas = document.getElementById("v3-viewer3D-canvas") as HTMLCanvasElement;
  viewer3D.setup(canvas);
}) 

let openViewer = async () => {
  // Reset isDoorOpen Array
  isDoorOpen.value = new Array(props.productData.products.length);
  for(let i = 0; i < isDoorOpen.value.length; i++) 
    isDoorOpen.value[i] = false;

  activeProductIndex.value = props.productData.startingIndex;
  await viewer3D.loadMeshes(props.productData);  // Load Meshes

  HoshizakiAnalyticsSingelton.getInstance().send_3D_viewer_loaded(props.productData.products[activeProductIndex.value].name); // Send Analytics event
}
let closeViewer = () => {
  viewer3D.cleanUp();

  // Reset activeProductIndex
  activeProductIndex.value = 0;
}

let playOpenDoorrAnimation = () => {
  viewer3D.playOpenDoorrAnimation(activeProductIndex.value, isDoorOpen.value[activeProductIndex.value]);
  isDoorOpen.value[activeProductIndex.value] = !isDoorOpen.value[activeProductIndex.value];
}

let switchMesh = (newIndex:number) => {
  viewer3D.switchMesh(props.productData.products, activeProductIndex.value, newIndex);
  setTimeout(() => { activeProductIndex.value = newIndex; }, 600);
}

defineExpose({
  closeViewer,
  openViewer
});

onUnmounted(() => {
  // TODO: Unregister On SituationComponent clicked event
  viewer3D.destroy();
})
</script>

<style>
.Viewer3D{
  width: 100%;
  height: 100%;
  background-color: #e9e9e9;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
}

/* Logo */
.v3-logo{
  position: absolute;
  left: 20px;
  top: 20px;
  pointer-events: none;
  width: 100px;
  height: 100px;
  z-index: 3;
}

/* Whitescreen */
.v3-whitescreen{
  pointer-events: none;
  opacity: 0;
  transition: .5s opacity ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 3;
}

/* Canvas */
.v3-viewer3D-canvas-container{
  position: relative;
  flex-grow: 1;
  height: 100%;
}
.v3-viewer3D-canvas-container canvas{
  width: 100%;
  height: 100%;
  outline: none;
}
</style>