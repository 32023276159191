import { name,version } from "../package.json";
import { Component } from 'vue';

// Analytics
import { HoshizakiAnalytics } from '@/analytics/HoshizakiAnalytics';
import { HoshizakiAnalyticsSingelton } from '@/analytics/AnalyticsSingelton';

// Logic & Interface
import { Flux360Logic } from 'flux-360-logic';
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import { Flux360Interface } from 'flux-360-interface';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';

// Configs
import logicConfig from '@/configs/config_logic.json';
import interfaceConfig from '@/configs/config_interface.json';

// Overwrite Components
import Viewer3D from "@/components/Viewer3D/Viewer3D.vue";
import ContentPanel from "@/components/ContentPanel.vue";
import MapComp from "@/components/Map/Map.vue";
import Watermark from "@/components/Watermark.vue";

//New Components
import CookieBanner from '@/components/CookieBanner.vue';
import ProductSearch from '@/components/ProductSearch/ProductSearch.vue';

// Loc Version
console.log("---- Starting " + name + " [" + version + "] ----");
console.log("");

// Create Logic & Interface
let flux360Logic = new Flux360Logic(logicConfig);
let flux360Interface = new Flux360Interface(interfaceConfig);
Flux360LogicSingleton.setInstance(flux360Logic);
Flux360InterfaceSingleton.setInstance(flux360Interface);

// Create Analytics
let analytics = new HoshizakiAnalytics();
analytics.setup(flux360Logic, flux360Interface);
HoshizakiAnalyticsSingelton.setInstance(analytics);

// Setup Interface
flux360Interface.setup(flux360Logic, new Map<string, Component>([["Viewer3D", Viewer3D], ["ContentPanel", ContentPanel], ["MapImage", MapComp], ["Watermark",Watermark]]), [CookieBanner,ProductSearch]);