<template>
    <div id="ContentPanel">
        <div class="cp-background" @click="closePanel()"/>
        <Frame class="content-frame">

            <!-- Header -->
            <template v-slot:header>
                <ContentPanelHeader/>
            </template>

            <!-- Body -->
            <template v-slot:body>
                <div class="pages-wrapper" :class="type">
                    
                    <!-- PDF -->
                    <PDFViewer v-if="type == 'pdf'" :url="pdfURL"/>

                    <!-- 3D VIEWER -->
                    <Viewer3D ref="viewer3D" v-show="type == '3D'" :productData="productData"/>
                </div>
            </template>

        </Frame>
    </div>
</template>

<script setup lang="ts">
import { ref, provide, onMounted} from 'vue';
import type { Ref } from 'vue';
import productList from "@/assets/3DViewer/products.json";
import collectionList from "@/assets/3DViewer/product-collections.json";
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';
import Viewer3D from '@/components/Viewer3D/Viewer3D.vue';
import type { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";
import { HoshizakiAnalyticsSingelton } from '../analytics/AnalyticsSingelton';

const viewer3D:Ref<typeof Viewer3D | null> = ref(null);

var productData = ref();
let title = ref("");
let type = ref("");
let pdfURL = ref("");

provide("title",title);

function openPanel(url:string){
    Flux360InterfaceSingleton.getInstance().getInterfaceStore().setContentPanelVisible(true);

    if(url.includes(".pdf")){ // Open PDF Viewer
        console.log("[ContentPanel] Open PDF Viewer for -> " + url);
        pdfURL.value = "/src/assets/pdf/" + url;
        type.value = "pdf";
        title.value = "Crescent Edge Ice"; // TODO: Get Title from config or product.json!
    }else{ // Open 3D Viewer
        console.log("[ContentPanel] Open 3D Viewer for collection -> " + url);
        
        // Find Collection with url == id
        var targetCollection =  {...collectionList.find((collection:any) => collection.id == url)!}; // Clone the Object!
        console.log(targetCollection.products);
        // Get full product informations out of the productlist based on the productnames
        var fullList = getCompleteProductlist(targetCollection.products);
        console.log(fullList);
        targetCollection.products = fullList;
        productData.value = targetCollection;

        type.value = "3D";
        // title.value = productData.value.id;
        title.value="";
        
        if(productData.value.products.length > 0){
            setTimeout(() => { viewer3D.value!.openViewer(); }, 0);
        } else {
            console.error("No products available or missing 3D-model -> ", productData.value.products);
            Flux360InterfaceSingleton.getInstance().getInterfaceStore().setContentPanelVisible(false);
        }
    }
}

function closePanel() {
    Flux360InterfaceSingleton.getInstance().getInterfaceStore().setContentPanelVisible(false);
    Flux360InterfaceSingleton.getInstance().getLifeCycleHooks().triggerOnContentPanelClosed();
}

// Get all Productinfos according to productName in collection
function getCompleteProductlist(productNames:string[]){
    const foundProducts:any[] = [];
    productNames.forEach(name => {
        var productObject = productList.find(product => product.name == name)
        if(productObject) foundProducts.push(productObject);
    })
    return foundProducts;
}

// Load PDF/3D Viewer when button is clicked
Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnSituationComponentClicked((situationComponent:SituationComponent) => {   
    if(situationComponent.getConfig().type == "Button"){
        let url = situationComponent.getConfig().url;
        openPanel(url);
    }
});

// When content Panel closed clean up 3D Viewer
Flux360InterfaceSingleton.getInstance().getLifeCycleHooks().registerOnContentPanelClosed(() => {
    if(type.value == "3D"){
        viewer3D.value!.closeViewer();
        HoshizakiAnalyticsSingelton.getInstance().send_product_popup_close(productData.value.id);
    }
});

onMounted(()=>{
    document.addEventListener("open3DViewer",(event) => {
        var customEvent = event as CustomEvent; 
        var url = customEvent.detail.url;
        openPanel(url);
    })
})
</script>

<style lang="scss">
#ContentPanel{
    // Overwrite styles
    background: none;
    z-index: 1;
    // --------

    .pages-wrapper.pdf{
        overflow-y:auto !important;
    }

    .cp-background{
        position: absolute;
        inset:0;
        background: rgba(var(--primary-color-1),.9);
        pointer-events: all;
    }

    .content-frame{
        z-index: 1;
        transform: none;
    }

    .header{
        .title-wrapper{
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}
</style>