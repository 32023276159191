import { Scene } from "@babylonjs/core/scene";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import '@babylonjs/core/Materials/Textures/Loaders/ktxTextureLoader'; // Needed for glb textures
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Animations/animatable"

import { Viewer3D } from "./Viewer3D";
import { getMedia } from "./ViteUtil";
import { playStartAnimation } from "./animations";
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";
import { Mesh } from "@babylonjs/core/Meshes/mesh";

export async function loadMeshes(viewer3D:Viewer3D, productData:any){
    return new Promise((resolve) => {
        console.log("[3D Viewer] Loading Meshes");

        // Load all models
        productData.products.forEach((product:any,i:number) => {
            let mainNode = new TransformNode(product.name);
            mainNode.setEnabled(i == productData.startingIndex);
            console.log(productData.startingIndex);

            // Fix Rotation of Products...
            fixProductRotation(product.name, mainNode);
            
            if(product.url){
                let url = getMedia("3DViewer/products/" + product.url);
                var meshTask = viewer3D.getAssetsManager().addMeshTask("", "", "", url);
                meshTask.onSuccess = (task) => {
                    task.loadedMeshes[0].parent = mainNode;

                    createCollider(viewer3D.getScene(), mainNode);
                    
                    if(meshTask.loadedAnimationGroups[0]) {
                        meshTask.loadedAnimationGroups[0].stop();
                        meshTask.loadedAnimationGroups[0].name = "Animation " + i; // Rename open animation for later usage
                    }
                };
                meshTask.onError = (task, message, exception) => {
                    console.log(message, exception);
                };
            }
        });


        viewer3D.getAssetsManager().onFinish = () => {
            console.log("[3D Viewer] AssetManager Finished");

            // Optimze meshes for performance
            // optimizeMeshPerformance(tasks);

            viewer3D.getEngine().resize(); // TODO: initial resize needed? Otherwise rendering size is wrong

            // Play starting animation
            playStartAnimation(viewer3D.getScene(), productData.products[productData.startingIndex]!.name);

            viewer3D.startRenderLoop();
            
            resolve(true);
        };

        viewer3D.getAssetsManager().load();
    });
}

// function optimizeMeshPerformance(tasks){
//     console.log("optimize Mesh Performance");

//     tasks.forEach(task => {
//         task.loadedMeshes.forEach(mesh => {
//             mesh.alwaysSelectAsActiveMesh = true;
//             mesh.isPickable = false;
//             mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
//             mesh.freezeWorldMatrix();
//             mesh.doNotSyncBoundingInfo = true;
//             if(mesh.material){
//                 mesh.material.backFaceCulling = true;
//                 if(
//                     mesh.material.name == "KC-AG-5500_Gestell" ||
//                     mesh.material.name == "KC-AG-5500_Details" || 
//                     mesh.material.name == "KC-AG_Details" ||
//                     mesh.material.name == "KC-AG_Gestell"
//                 ){
//                     mesh.material.backFaceCulling = false;
//                 }
//                 // mesh.material.freeze();
//             }
//         });
//     });

//     // Unfreeze world matrix for animated objects!
//     window.scene3DViewer.animationGroups.forEach(animation => {
//         animation.stop();
//         animation.children.forEach(children => { 
//             children.target.unfreezeWorldMatrix();
//             children.target.getDescendants(false).forEach(node => {
//                 node.unfreezeWorldMatrix();
//             });
//         }); 
//     });

//     console.log("Mesh Optimization Done");
// }

function createCollider(scene:Scene, mainNode:TransformNode){
    let boundingInfo = calculateBoundingInfo(mainNode);

    let collider = MeshBuilder.CreateBox("Collider", {height:0}, scene);
    collider.setBoundingInfo(boundingInfo);
    collider.isPickable = false;
    collider.isVisible = false;
    collider.parent = mainNode;
    collider.showBoundingBox = true;
}

function calculateBoundingInfo(mainNode:TransformNode){
    const meshes = mainNode.getChildMeshes();
    let meshMinMax = Mesh.MinMax(meshes);
    let newBoundingInfo = new BoundingInfo(meshMinMax.min,meshMinMax.max);
    return newBoundingInfo!;
}

function fixProductRotation(name:string, mainNode:TransformNode){
    if(
        name == "COMPACT K 220" || 
        name == "COMPACT F 220" ||
        name == "COMPACT KG 220" ||
        name == "COMPACT FG 220" ||
        name == "COMPACT F 420" ||
        name == "COMPACT K 420" ||
        name == "COMPACT FG 420" ||
        name == "COMPACT KG 420"
    )
        mainNode.rotation = new Vector3(0,Math.PI/2,0);
}