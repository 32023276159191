import { Analytics } from "@/analytics/Analytics";
import { Flux360Interface } from 'flux-360-interface';
import { Flux360Logic } from 'flux-360-logic';
import collectionJSON from "@/assets/3DViewer/product-collections.json";
import type { Situation } from "flux-360-logic/dist/src/core/Situation";
import type { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";
import type { PanoramaOverlayAnimation } from "flux-360-logic/dist/src/core/situationComponents/PanoramaOverlayAnimation";

export class HoshizakiAnalytics {

    private analytics:Analytics;
    private timeSessionStarted:number|undefined;
    private timeProductOpen:number|undefined;
    private openProductName:string|undefined;

    private flux360Logic:Flux360Logic;
    private flux360Interface:Flux360Interface;

    constructor(){
        this.analytics = new Analytics();
    }

    public setup(flux360Logic:Flux360Logic, flux360Interface:Flux360Interface) {    
        this.analytics.setup("G-XX6GGMRT3E");
        this.flux360Logic = flux360Logic;
        this.flux360Interface = flux360Interface;

        this.registerEvents();
    } 

    public sendEvent(eventName:string, eventData:any) {
        this.analytics.sendEvent(eventName, eventData);
    }

    public cookieConsent(){
        this.analytics.cookieConsent();
    }

    private registerEvents(){
        this.register_session_started();
        this.register_session_loaded();
        this.register_session_ended();
        this.register_tutorial();
        this.register_fulscreen();
        this.register_change_situation();
        this.register_product_hover();
        this.register_product_popup_open();
        this.register_animation_started();
    }
    
    // session_started
    private register_session_started(){
        this.analytics.sendEvent("session_started", {});
        this.timeSessionStarted = new Date().getTime();
    }

    // session_loaded
    private register_session_loaded(){
        let firstTime = true;
        this.flux360Logic.getLifeCycleHooks().registerOnAfterSituationEnter( (newSituationID: number, oldSituationID: number|undefined) => {
            if(firstTime){
                let timeLoaded = new Date().getTime() - this.timeSessionStarted!;
                timeLoaded /= 1000;
                timeLoaded = Math.round(timeLoaded);
                this.analytics.sendEvent("session_loaded", {loading_time: timeLoaded});
            }

            firstTime = false;
        })
    }

    // session_ended
    private register_session_ended(){
        window.addEventListener("beforeunload", (e) => {

            // Send Product Popup Close Event if Opened
            if(this.timeProductOpen){
                let timeOpen = new Date().getTime() - this.timeProductOpen!;
                timeOpen /= 1000;
                timeOpen = Math.round(timeOpen);
                this.analytics.sendEvent("product_popup_close", {product: this.openProductName, time_open: timeOpen});
            }
            
            // Send Session Ended 
            let timeOpen = new Date().getTime() - this.timeSessionStarted!;
            timeOpen /= 1000;
            timeOpen = Math.round(timeOpen);
            this.analytics.sendEvent("session_ended", {time_open: timeOpen});

            // e.preventDefault(); // Check if analytics data is send
        });
    }

    // Tutorial
    private register_tutorial(){
        // tutorial_change_step
        this.flux360Interface.getLifeCycleHooks().registerOnTutorialStepChanged((step: number) => {
            this.analytics.sendEvent("tutorial_change_step", {step: step});
        });

        // tutorial_show
        this.flux360Interface.getLifeCycleHooks().registerOnTutorialOpened(() => {
            this.analytics.sendEvent("tutorial_show", {});
        });

        // tutorial_close
        this.flux360Interface.getLifeCycleHooks().registerOnTutorialClosed(() => {
            this.analytics.sendEvent("tutorial_close", {});
        });
    }

    // Fullscren
    private register_fulscreen(){
        // fullscreen_enabled
        this.flux360Interface.getLifeCycleHooks().registerOnFullscreenEnabled(() => {
            this.analytics.sendEvent("fullscreen_enabled", {});
        });

        // fullscreen_disabled
        this.flux360Interface.getLifeCycleHooks().registerOnFullscreenDisabled(() => {
            this.analytics.sendEvent("fullscreen_disabled", {});
        });
    }

    // change_situation
    private register_change_situation(){
        this.flux360Logic.getLifeCycleHooks().registerOnAfterSituationEnter((newSituationID: number, oldSituationID: number|undefined) => {
            let newSituationName = this.flux360Logic.getTour().getSituations().find((situation:Situation) => situation.getID() == newSituationID)!.name;
            this.analytics.sendEvent("change_situation", { "situation": newSituationName });
        })
    }

    // product_hover
    private register_product_hover(){
        this.flux360Logic.getLifeCycleHooks().registerOnSituationComponentHoverOver((situationComponent:SituationComponent) => {   
            if(situationComponent.getConfig().type == "Button"){
                let productData = collectionJSON.find((collection:any) => collection.id == situationComponent.getConfig().url)!; // Get ProductData from JSON
                if(productData){
                    this.analytics.sendEvent("product_hover", {product: productData.id});
                }
            }
        });
    }

    // product_popup_open
    private register_product_popup_open(){
        this.flux360Logic.getLifeCycleHooks().registerOnSituationComponentClicked((situationComponent:SituationComponent) => {   
            if(situationComponent.getConfig().type == "Button"){
                let productData = collectionJSON.find((collection:any) => collection.id == situationComponent.getConfig().url)!; // Get ProductData from JSON
                if(productData){
                    this.timeProductOpen = new Date().getTime();
                    this.openProductName = productData.id;
                    this.analytics.sendEvent("product_popup_open", {product: this.openProductName});
                }
            }
        });
    }

    // animation_started
    private register_animation_started(){
        this.flux360Logic.getLifeCycleHooks().registerOnBeforePanoramaAnimationStarted((panoramaOverlayAnimation:PanoramaOverlayAnimation) => {
            // @ts-ignore
            let videoID = panoramaOverlayAnimation.getConfig().videoMediaID;
            let productName = "";
            if(videoID == 21)
                productName = "PREMIER K BW80"; 
            if(videoID == 22)
                productName = "PREMIER K 70";
            if(videoID == 23)
                productName = ""; // Dont know the product name
            if(videoID == 28 || videoID == 17)
                productName = "BeerMatic DualTap";

            // @ts-ignore
            this.analytics.sendEvent("animation_started", {product: productName, situation: panoramaOverlayAnimation.getSituation().getName() });
        })
    }

    // 3D_viewer_loaded
    public send_3D_viewer_loaded(productName:string){
        let timeLoaded = new Date().getTime() - this.timeProductOpen!;
        timeLoaded /= 1000;
        timeLoaded = Math.round(timeLoaded);
        this.analytics.sendEvent("viewer_loaded ", {product: productName, loading_time: timeLoaded});
    }

    // product_popup_close
    public send_product_popup_close(productName:string){
        let timeOpen = new Date().getTime() - this.timeProductOpen!;
        timeOpen /= 1000;
        timeOpen = Math.round(timeOpen);
        this.timeProductOpen = undefined;
        this.openProductName = undefined;
        this.analytics.sendEvent("product_popup_close", {product: productName, time_open: timeOpen});
    }
}