import { Scene } from "@babylonjs/core/scene";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { Animation } from "@babylonjs/core/Animations/animation";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";

import { getCameraRadiusForProduct, setDefaultCameraRadiusLimits, getCameraTargetForProduct, getCameraWheelPrecisionForProduct } from "./util";

export async function playOpenDoorrAnimation(scene:Scene, activeProductIndex:number, isDoorOpen:boolean){
    return new Promise((resolve) => {
        enableUI(false);

        let animationGroup = scene.animationGroups.find(animationGroup => animationGroup.name ==  "Animation " + activeProductIndex)!;
        animationGroup.onAnimationGroupEndObservable.addOnce(() => {
            enableUI(true);
            resolve(true);
        });

        if(isDoorOpen){ 
            animationGroup.start(false, 1, animationGroup.to, 0); // Reverse Animation
        }else{
            animationGroup.start();
        }
    });
}

export async function switchMesh(scene:Scene, products:any[], oldIndex:number, newIndex:number){
    let ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    return new Promise((resolve) => {
        enableUI(false);

        let loadingscreen = document.getElementById("v3-whitescreen") as HTMLDivElement;
        let camera = scene.activeCamera as ArcRotateCamera;

        // Animate Camera Out
        let distance = 50;
        let distanceOut = newIndex > oldIndex ? distance : -distance;
        let targetXOut = Math.sin(camera.alpha) * distanceOut;
        let targetZOut = -Math.cos(camera.alpha) * distanceOut;
        Animation.CreateAndStartAnimation("", camera, "target.x", 60, 50, camera.target.x, targetXOut, 0, ease);
        Animation.CreateAndStartAnimation("", camera, "target.z", 60, 50, camera.target.z, targetZOut, 0, ease);

        // Show Whitescreen right before Camera Out Animation finished
        setTimeout(() => { loadingscreen.style.opacity = "1"; }, 200);

        setTimeout(() => {
            // Hide Whitescreen
            loadingscreen.style.opacity = "0";

            // Change Radius when switching meshes (Meshes have different sizes so adjust so it fits into the view)
            camera.radius = getCameraRadiusForProduct(scene, products[newIndex].name);
            setDefaultCameraRadiusLimits(scene);

            // Change Mesh
            scene.getNodeByName(products[oldIndex].name)!.setEnabled(false); // Disabled current active meshes
            scene.getNodeByName(products[newIndex].name)!.setEnabled(true) // Enable new Mesh

            // Animate Camera In
            camera.target.x = -targetXOut;
            camera.target.z = -targetZOut;

            let target = getCameraTargetForProduct(scene, products[newIndex].name);
            camera.target.y = target.y;

            Animation.CreateAndStartAnimation("", camera, "target.x", 60, 50, camera.target.x, target.x, 0, ease);
            Animation.CreateAndStartAnimation("", camera, "target.z", 60, 50, camera.target.z, target.z, 0, ease, () => {
                enableUI(true);
                resolve(true);
            });

        }, 800);
    });
}

export function playStartAnimation(scene:Scene, name:string){
    console.log("[3D Viewer] Play Start Animation");

    enableUI(false);
    (document.getElementsByClassName("close-btn-wrapper")[0] as HTMLDivElement).style.pointerEvents = 'none';

    let ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    // Setup Camera
    const camera = scene.activeCamera as ArcRotateCamera;
    camera.target = getCameraTargetForProduct(scene, name);
    camera.radius = getCameraRadiusForProduct(scene, name);
    camera.wheelPrecision = getCameraWheelPrecisionForProduct(scene,name);

    camera.lowerRadiusLimit = 0;
    camera.upperRadiusLimit = 999999; 
    
    Animation.CreateAndStartAnimation("", camera, "alpha", 60, 180, 0.8, 1, 0, ease);
    Animation.CreateAndStartAnimation("", camera, "beta", 60, 180, 0.8, 1.1, 0, ease);
    Animation.CreateAndStartAnimation("", camera, "radius", 60, 180, camera.radius * 1.2,  camera.radius, 0, ease, () => {
        setDefaultCameraRadiusLimits(scene);
        enableUI(true);
        (document.getElementsByClassName("close-btn-wrapper")[0] as HTMLDivElement).style.pointerEvents = '';
    });
}

// Disable 3D Viewer UI so user cant press buttons when animations are playing
function enableUI(enable:boolean){
    let openContainerButton = document.getElementsByClassName("v3-button-animation")[0] as HTMLDivElement;
    if(openContainerButton){
        openContainerButton.style.opacity = enable ? '' : "0.5";
        openContainerButton.style.pointerEvents = enable ? '' : 'none';
    }
    let switchButtons = document.getElementsByClassName("v3-buttons-modell-container")[0] as HTMLDivElement;
    if(switchButtons){
        switchButtons.style.opacity = enable ? '' : "0.5";
        switchButtons.style.pointerEvents = enable ? '' : 'none';
    }
}