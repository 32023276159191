<template>
 <div id="CustomMap">
    <div class="grid-wrapper">
        <div class="grid">
            <div v-if="activeSituationID != 1" class="tile center-tile" @click="jumpToRoom(1)" style="margin-right: 8px;">
                <span style="transform: rotate(180deg) translateY(+2px)">
                    <img src="@/assets/icons/arrow_white.svg" style="height:24px; width:24px; margin-left: 8px;" alt="">
                </span>
                back to CENTER
            </div>
            <div v-for="(room,index) in rooms" :key="index" class="tile">
                <div class="overlay" @click="jumpToRoom(room.situationID)">
                    <div class="title-wrapper">
                        <img :src="room.icon" alt="">
                        <h2>{{room.name}}</h2>
                    </div>
                    <div class="btn-container">
                        <!-- <button class="btn-zoom-in" @click=""/> -->
                        <button class="btn-travel-to" @click="">
                            <!-- <img src="@/assets/icons/footprints_white.svg" alt=""> -->
                            <img src="@/assets/icons/arrow_white.svg" style="height:24px; width:24px;" alt="">
                        </button>
                    </div>
                </div>
                <img :src="room.img" :alt="'image of ' + room.name" class="tile-bg">
            </div>
        </div>
    </div>
 </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';

let activeSituationID = ref(0);
Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnBeforeSituationEnter((newSituationID:number) => {
    console.log("new Situaiton ID -> " + newSituationID);
    activeSituationID.value = newSituationID;
});

let rooms = [
    {
        name:"Bar",
        img:"/src/assets/Map/Hoshizaki_Bar_u_Cellar_TopDown.png",
        imgRot:0,
        icon:"/src/assets/Map/pictogram_bar-area.png",
        situationID:6
    },
    {
        name:"Kitchen",
        img:"/src/assets/Map/Hoshizaki_Kitchen_TopDown.png",
        imgRot:0,
        icon:"/src/assets/Map/pictogram_kitchen-area.png",
        situationID:4
    },
    {
        name:"Restaurant",
        img:"/src/assets/Map/Hoshizaki_Restaurant_TopDown.png",
        imgRot:90,
        icon:"/src/assets/Map/pictogram_restaurant-area.png",
        situationID:3
    },    
    {
        name:"Quickservice",
        img:"/src/assets/Map/Hoshizaki_QuickServices_TopDown.png",
        imgRot:0,
        icon:"/src/assets/Map/pictogram_quickservice.png",
        situationID:8
    }
]

function jumpToRoom(situationID:number){
    Flux360LogicSingleton.getInstance().getTour().changeSituation(situationID, {playAnimation:false});
}
</script>

<style lang="scss" scoped>
.grid{
    --bg-clr: 30,30,30;
    --tile-h: 120px;
    --tile-w: 200px;


    display: grid;
    grid-template-columns: repeat(2, var(--tile-w));
    background: rgb(var(--bg-clr));
    color: white;
    

    .tile{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--tile-h);
        cursor:pointer;

        &.center-tile{
            cursor: pointer;
            font-weight: bold;

            &::after{
                content:'';
                position: absolute;
                inset: 0px;
                // background: rgb(var(--accent-color-1));
                background: rgb(var(--secondary-color-1));
                opacity:0;
                transition: opacity 0.2s ease;
            }

            &:hover::after{
                opacity:0.1;
            }
        }

        &:hover{
            .overlay{
                background-color: rgba(var(--bg-clr),0.5);
            }

            .btn-container{
                max-height:96px;
                opacity: 1;
                pointer-events: all;
            }
        }
;
        .overlay{
            position: absolute;
            inset: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            z-index:1;

            background-color: rgba(var(--bg-clr),0.8);

            opacity: 1;
            transition: all 0.1s ease;
        }

        .title-wrapper{     
            display: flex;
            flex-direction: row;
            gap:6px;

            img{
                height:24px;
                width: 24px;
            }

            h2{
                margin:0;
                margin-bottom: 4px;
                font-size: 1.1em;
                text-transform: uppercase;
            }
        }

        .btn-container{
            display: flex;
            flex-direction: row;
            gap: 6px;
            justify-content: center;
            align-items: center;
            max-height:0;
            opacity: 0;
            pointer-events: none;
            transition: all .2s ease;
            overflow:hidden;

            button{
                min-height: 0 !important;
                min-width: 0 !important;
                height: 32px !important;
                // width: 32px !important;
                // background-color: rgb(var(--accent-color-1)) !important;

                img{
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .tile-bg{
            height: calc(100% - 24px);
            width: calc(100% - 24px);
            object-fit: contain;
            filter: saturate(0) blur(1px);
            z-index:0;
        }
    }
}

</style>

<style lang="scss">
.menubar-left button{
    order: 99;

    &#button-map{
        order: 1;
    }
}

// Change other Element-Styles in Navigation
div.situationName{
    text-transform: uppercase;
    font-weight: bold;
}

#SituationGallery .situation{
    text-transform: uppercase;
    font-weight: bold;
    gap: 12px;
    font-family: var(--font-family-1);
}
</style>
