<template>
        <Teleport to="#Menubar .menubar-watermark-slot">
        <div class="Watermark">
            <img src="@/assets/interface/logo-black.png"/>
        </div>
    </Teleport>
</template>
  
<script setup lang="ts">
import { onBeforeMount, onMounted } from 'vue';

// const logoURL = ConfigManager.getConfig().logos[0].logo!;

onBeforeMount(()=>{
    console.log("Mount Watermark")
})
onMounted(()=>{
    console.log("Watermark mounted");
})
</script>

<style lang="scss">
.Watermark {
    position: relative !important;
    height: var(--bottom-bar-height) !important;
    padding: 16px !important;
    padding-right: 24px !important;
    box-sizing: border-box !important;

    img{
        height: 100% !important;
    }
}
</style>