<template>
    <div class="v3-buttons-wrapper">
        <!-- Switch Products Button -->
        <div v-if="productData.products.length > 1" class="v3-buttons-modell-container">
            <button class="v3-buttons-modell" v-for="product,i in productData.products" :key="product.name" @click="switchMesh(i)"
            :disabled="product.isDisabled"
            :class="{'active' : activeProductIndex == i, 'large': product.buttonName && product.buttonName.length > 6}"
            :style="{
                // background: i == 0 ? '#00536B' : i == 1 ? '#006B89' : i == 2 ? '#008CB3' : i == 3 ? '#00C7FF' : '#08C5FB',
            }">
                {{product.buttonName ? product.buttonName : i == 0 ? 'K' : i == 1 ? 'M' : i == 2 ? 'F' : 'KG'}}
    
                <div v-if="product.note" class="v3-buttons-modell-note">
                    {{ product.note }}
                </div>
            </button>
        </div>
        
        <!-- Open Door Button -->
        <div v-if="!productData.products[activeProductIndex].noAnimation" class="v3-button-animation" @click="playAnimation()">
            <img src="@/assets/icons/ic_door-open_white.svg" alt="">
            <!-- <img src="@/assets/icons/ic_door-open_dark.svg" alt=""> -->
            <!-- <img src="@/assets/icons/ic_door-open_cyan.svg" alt=""> -->
        </div>
    </div>
</template>

<script setup lang="ts">
import { HoshizakiAnalyticsSingelton } from '@/analytics/AnalyticsSingelton';

let props = defineProps({
    playOpenDoorrAnimation: {
        type: Function,
        required: true
    },
    switchMesh: {
        type: Function,
        required: true
    },
    activeProductIndex: {
        type: Number,
        required: true
    },
    productData: {
        type: Object,
        required: true
    }
})

let playAnimation = () => {
    props.playOpenDoorrAnimation();
    HoshizakiAnalyticsSingelton.getInstance().sendEvent("viewer_animation", {product: props.productData.products[props.activeProductIndex].name});
}
let switchMesh = (index:number) => {
    if(index != props.activeProductIndex){
        props.switchMesh(index);
        HoshizakiAnalyticsSingelton.getInstance().sendEvent("viewer_buttons", {product: props.productData.products[index].name});
    }
}
</script>

<style scoped lang="scss">
.v3-buttons-wrapper{
    --button-size: 56px;
    position: absolute;
    bottom: 12px;
    width: 100%;

    display:flex;
    justify-content: center;
}

/* Mesh Buttons */
.v3-buttons-modell-container{
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease;
    background: rgba(var(--primary-color-1),1);
    border-radius: var(--radius-1);
}
.v3-buttons-modell{
    position: relative;
    height: var(--button-size);
    width: var(--button-size);
    min-width: 0 !important;
    padding:0;
    
    display: flex;
    justify-content: center;
    align-items: center;

    // border-radius: calc(var(--button-size) / 2);
    border-radius: 2px;
    cursor: pointer;
    background: rgba(var(--primary-color-1),1);
    transition: background .4s ease, transform .4s ease, filter .1s ease;
    
    color: white;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    white-space: pre-line;
    user-select: none;

    &:hover{
        // transform: scale(1.1);
        filter: brightness(1.2);
    }

    &.active{
        background: rgba(var(--accent-color-1),1);

        &:hover{
            filter: brightness(1.1);
        }
    }
    &.large{
        font-size: 0.7em;
        // width: unset;
        width: calc(var(--button-size) * 1.5);
        padding:0 8px;
    }

    
    &[disabled]{
        opacity: 1;
        color:rgba(255,255,255,0.4);
        pointer-events: none;
        background: rgb(var(--primary-color-1)) !important;
    }
}

/* Note */
.v3-buttons-modell-note{
    position: absolute;
    color: rgba(var(--accent-color-1),1);
    bottom: calc(100% + 8px);
}

/* Animation */
.v3-button-animation{
    position: absolute;
    right: 24px;
    bottom: 0px;
    width: var(--button-size);
    height: var(--button-size);
    transition: transform .4s ease, opacity .3s ease;
    cursor: pointer;
    // background-color: rgb(var(--accent-color-1));
    background-color: rgb(var(--primary-color-1));
    outline-offset: -2px;
    // outline: rgb(var(--accent-color-1)) 4px solid;
    // outline: rgb(var(--primary-color-1)) 4px solid;
    border-radius: calc(var(--button-size) / 2);

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease;

    &:hover{
        filter: brightness(1.1)
    }
    
    img{
        width: 32px;
        height: 32px;
        // filter: invert(1);
        transform: scale(-1);
    }
}
</style>