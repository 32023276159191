import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Scene } from "@babylonjs/core/scene";

export function getCameraRadiusForProduct(scene:Scene, name:string){
    var boundingbox = getBoundingBox(scene, name);
    let distance = Vector3.Distance(boundingbox.maximum, boundingbox.minimum);
    return distance * 1.1;
}

export function setDefaultCameraRadiusLimits(scene:Scene){
    let camera = scene.activeCamera as ArcRotateCamera;
    camera.lowerRadiusLimit = camera.radius * 0.5;
    camera.upperRadiusLimit = camera.radius * 1.2;
}

export function getCameraTargetForProduct(scene:Scene, name:string){
    var boundingbox = getBoundingBox(scene, name);
    let target = new Vector3(boundingbox.centerWorld.x, boundingbox.centerWorld.y, boundingbox.centerWorld.z);
    return target;
}

export function getCameraWheelPrecisionForProduct(scene:Scene, name:string){
    var camRadius = getCameraRadiusForProduct(scene, name);
    return 175/camRadius;
}

export function getBoundingBox(scene:Scene, name:string){
    const model = scene.getNodeByName(name)!.getChildren().find(mesh => mesh.name == "Collider") as Mesh;
    var boundingbox = model.getBoundingInfo().boundingBox;
    return boundingbox;
}