

<template>
    <div id="v3-loadingscreen" class="v3-loadingscreen">
        <img class="v3-loadingscreen-logo" src="@/assets/interface/logo-black.png" alt="">
        <div class="v3-loadingscreen-text" id="v3-loadingscreen-text">0%</div>
        <svg class="v3-loadingscreen-animation" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML"  type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
            </path>
        </svg>
    </div>
</template>

<style>
.v3-loadingscreen{
    height: 100%;
    pointer-events: none;
    opacity: 1;
    transition: 1s opacity ease-in-out;
    position: absolute;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.v3-loadingscreen-logo{
    width: 350px;
}
.v3-loadingscreen-animation{
    fill: #0080A1;
    width: 200px;
    height: 200px;
    display: block;
}
.v3-loadingscreen-text{
    font-size: 24px;
    margin-top: 20px;
    color: #0080A1;
    text-align: center;
    font-weight: bolder;
}
</style>