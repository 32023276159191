<template>
    <div class="v3-sidebar">
        <div class="v3-sidebar-name" v-if="productData.products[activeProductIndex].name">{{productData.products[activeProductIndex].name}}</div>
        <div class="v3-sidebar-subtitle" v-if="productData.products[activeProductIndex].subtitle">{{productData.products[activeProductIndex].subtitle}}</div>
        <div class="v3-sidebar-short-description" v-if="productData.products[activeProductIndex].fullDescription">{{productData.products[activeProductIndex].fullDescription}}</div>
            
        <!-- Large discription -->
        <div class="v3-sidebar-additionalInfo-container" v-if="productData.products[activeProductIndex].description">
            <div class="v3-sidebar-additionalInfo" :style="{height: expandetDescription ? getTableHeightDescription() : '300px'}">
                
                <!-- Discription -->
                <div id="v3-sidebar-description-list">       
                    <div v-for="description in productData.products[activeProductIndex].description" :key="description.title" class="v3-sidebar-description">
                        <div v-if="description.title" class="v3-sidebar-description-title v3-sidebar-label">{{description.title}}</div>
                        <p v-if="description.description">{{description.description}}</p>
                        <div v-if="description.img" class="v3-sidebar-description-img-wrapper" >
                            <img :src="'src/assets/3DViewer/products/'+description.img" alt="">
                        </div>
                        <ul v-if="description.list">
                            <li v-for="item in description.list" :key="item">{{item}}</li>
                        </ul>
                    </div>
                </div>

                <!-- Arrow -->
                <div class="v3-sidebar-additionalInfo-arrow" @click="expandDescription()" :style="{background: expandetDescription ? 'transparent' : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 50%)'}">
                    <div class="v3-sidebar-chevron" style="transform: rotate(180deg); top: 25px;" v-show="expandetDescription"/>
                    <div class="v3-sidebar-chevron" v-show="!expandetDescription"/>
                </div>
            </div>
        </div>

        <!-- Data Table -->
        <div class="v3-sidebar-additionalInfo-container"  v-if="productData.products[activeProductIndex].additionalInfos">
            <div class="v3-sidebar-additionalInfo" :style="{height: expandetAdditionalInfo ? getTableHeightAdditionalInfo() : '300px'}">
                
                <!-- Table -->
                <table id="v3-sidebar-additionalInfo" cellspacing="0">
                    <tr v-for="data in productData.products[activeProductIndex].additionalInfos" :key="data.label">
                        <td>{{data.label}}</td>
                        <td>{{data.value}}</td>
                    </tr>
                </table>

                <!-- Arrow -->
                <div class="v3-sidebar-additionalInfo-arrow" @click="expandAdditionalInfos()" :style="{background: expandetAdditionalInfo ? 'transparent' : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 50%)'}">
                    <div class="v3-sidebar-chevron" style="transform: rotate(180deg); top: 25px;" v-show="expandetAdditionalInfo"></div>
                    <div class="v3-sidebar-chevron" v-show="!expandetAdditionalInfo"></div>
                </div>
            </div>
        </div>

        <!-- Product-gallery -->
        <div class="v3-sidebar-productlist-container" v-if="productData.products[activeProductIndex].categories">
            <div class="v3-sidebar-productlist" v-for="(category,id) in productData.products[activeProductIndex].categories" :key="id">
                <div class="v3-sidebar-productlist-label v3-sidebar-label">{{ category.label }}</div>
                <div class="v3-sidebar-productlist-list">
                    <div class="v3-sidebar-productlist-item" v-for="(counter,id) in category.counters" :key="id" :class="{'clickable':counter.documents.length <= 1}" @click="counter.documents.length <= 1 ? openPDF(counter.documents[0].url):null">
                        <div class="item-image-wrapper">
                            <img :src="'src/assets/3DViewer/products/'+counter.thumbnail" alt="" :class="{'isCounter':productData.id.toLowerCase().includes('counters')}">
                            <div class="item-specification-button-container">
                                <button v-for="(document,id) in counter.documents" class="item-specification-button" @click="openPDF(document.url)">
                                    <span class="document">{{document.code.toUpperCase()}}</span> Specification
                                </button>
                            </div>
                        </div>
                        <div class="item-description-wrapper">
                            <h1>{{ counter.name }}</h1>
                            <p>
                                {{ counter.regions.length > 0 ? 'Available in' : ''}}
                                <span class="item-description-region-label" v-if="counter.regions.length > 0" v-for="(region,id) in counter.regions" :key="id">{{region}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="v3-sidebar-buttons" v-if="productData.products[activeProductIndex].documents && productData.products[activeProductIndex].documents.length>0">
            <button class="v3-sidebar-button" v-for="(document,index) in productData.products[activeProductIndex].documents" :key="index" @click="openPDF(document.url)">
                {{document.label}}
            </button>
        </div>

        <div class="v3-sidebar-contact">
            <button class="v3-sidebar-button" @click="openFormular()">Contact Form</button>
        </div>

    </div>
</template>
  
<script setup lang="ts">
import { ref, watch } from 'vue';
import { getMedia } from "../scripts/ViteUtil";
import { HoshizakiAnalyticsSingelton } from '@/analytics/AnalyticsSingelton';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';

let props = defineProps({
    activeProductIndex: {
        type: Number,
        required: true
    },
    productData: {
        type: Object,
        required: true
    }
})

let expandetAdditionalInfo = ref(false);
let expandetDescription = ref(false);

watch(() => props.activeProductIndex,() => {
    expandetAdditionalInfo.value = false;
    expandetDescription.value = false; 
})

let openFormular = () => {
    HoshizakiAnalyticsSingelton.getInstance().sendEvent("product_open_contact", {product: props.productData.products[props.activeProductIndex].name});

    window.open('https://hoshizaki-europe.com/tmp-form-page/?product-name=' + props.productData.products[props.activeProductIndex].name, '_blank', 'popup=true, height=900, width=600');

    // Gray out showroom
    document.body.style.transition = ".3s filter ease";
    document.body.style.filter = "brightness(0.1)";

    // When Showroom in focus again remove gray
    window.addEventListener("focus", () => {
        document.body.style.filter = "none"
    }, { once: true});
}

let openPDF = (url:string) => {
    HoshizakiAnalyticsSingelton.getInstance().sendEvent("product_popup_download_file", {url: url, product: props.productData.products[props.activeProductIndex].name});
    window.open(getMedia("3DViewer/products/" + url),'_blank');
}

let getTableHeightAdditionalInfo = () => {
    return document.getElementById("v3-sidebar-additionalInfo")!.clientHeight + 80 + 'px';
}

let getTableHeightDescription = () => {
    let height = document.getElementById("v3-sidebar-description-list")!.clientHeight + 80 + 'px';
    return height;
}

let expandAdditionalInfos = () => {
    expandetAdditionalInfo.value = !expandetAdditionalInfo.value;
    if(expandetAdditionalInfo.value)
        HoshizakiAnalyticsSingelton.getInstance().sendEvent("product_popup_extend_text", {product: props.productData.products[props.activeProductIndex].name});
}

let expandDescription = () => {
    expandetDescription.value = !expandetDescription.value;
    if(expandetDescription.value)
        HoshizakiAnalyticsSingelton.getInstance().sendEvent("product_popup_extend_text", {product: props.productData.products[props.activeProductIndex].name});
}

Flux360InterfaceSingleton.getInstance().getLifeCycleHooks().registerOnContentPanelClosed(() => {
    expandetDescription.value = false;
    expandetAdditionalInfo.value = false;
});
</script>

<style lang="scss" scoped>
.v3-sidebar{
    --padding-h: 40px;
    --padding-v: 20px;
    --tile-size: 160px;
    --grid-gap: 8px;

    position: relative;
    background: white;
    width: calc(33%);
    padding: var(--padding-h);
    padding-top: var(--padding-v);
    padding-bottom: 0px;
    right: 0;
    transition: right .6s ease;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    ::selection{
        background-color: rgb(var(--accent-color-1));
        filter: invert(1);
    }
}
.v3-sidebar-name, .v3-sidebar-subtitle{
    font-size: 22px;
    font-weight: 700;
    font-weight: bold;
    color: rgb(var(--accent-color-1));
}

.v3-sidebar-subtitle{
    font-weight: normal;
    font-size: 18px;
    color: rgb(var(--secondary-color-1));
}

.v3-sidebar-label{
    background: rgb(var(--accent-color-1));
    padding: 2px 4px;
    margin-bottom: 8px;
    color: white;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
}
.v3-sidebar-short-description{
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
}
#v3-sidebar-description-list{
    white-space: pre-line;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 15px;

    .v3-sidebar-description{
        display:flex;
        flex-direction: column;
        align-items: flex-start;

        .v3-sidebar-description-title{
            font-weight: bold;
            margin-top: 24px;
            margin-bottom: 5px;
        }
    
        .v3-sidebar-description-img-wrapper{
            img{
                width:100%;
            }
        }
    }

}
.v3-sidebar-additionalInfo-container{
    width: 100%;
    .v3-sidebar-additionalInfo{
        width: 100%;
        text-align: left;
        transition: height .4s ease, filter .4s ease;
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
        border-collapse: collapse;
    
        tr{
            font-weight: lighter;
        }
    
        td{
            padding: 5px;
            font-weight: lighter;
            vertical-align: baseline;
            border-bottom: 1px solid rgba(0,0,0,0.1);
    
            &:nth-child(even){
                font-weight: bold;
            }
        }
    
        .v3-sidebar-additionalInfo-arrow{
            padding-top: 60px;
            width: 100%;  
            height: 110px;
            cursor: pointer;
            position: absolute;
            bottom: 0px;     
            display: flex;
            justify-content: center;
            align-items: center;
    
            .v3-sidebar-chevron{
                --size: 32px;
    
                box-sizing: border-box;
                position: relative;
                width:  var(--size);
                height: var(--size);
                // border: 2px solid black;
                border-radius: 100px;
                transition: all .3s ease;
    
                &::after{
                    content: "";
                    position: absolute;
                    display:block;
                    top:50%;
                    left:50%;
                    
                    width:  calc(var(--size) / 3);
                    height: calc(var(--size) / 3);
                    border-bottom: 2px solid;
                    border-right: 2px solid;
    
                    transform: translate(-50%,-75%) rotateZ(45deg);
                    transition: all .3s ease;
                }
    
                &:hover{
                    background: black;
                    &::after{
                        border-color: white;
                    }
                }
            }
        }
    }
}

.v3-sidebar-buttons{
    width: 100%;
}
.v3-sidebar-button{
    box-sizing:border-box;
    background: transparent;
    font-size: 15px;
    color: black;
    cursor: pointer;
    padding: 10px 22px;
    text-align: center;
    transition: background .2s ease;
    font-weight: 700;
    margin-bottom: 12px;
    border: solid 2px black;
    width: 100%;

    &:nth-last-child(1){
        margin-bottom: 0px;
    }

    &:hover{
        background: black;
        color: white;
    }
}

.v3-sidebar-contact{
    position: sticky;
    bottom: 0px;
    background-color: white;
    padding: 12px 40px;
    margin-left: -40px;
    margin-right: -40px;
    box-sizing: border-box;


}

.v3-sidebar-productlist{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top:16px;
    margin-bottom: 32px;
}
.v3-sidebar-productlist-list{
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(var(--tile-size), 1fr));
    width: 100%;
    gap: var(--grid-gap);

    .v3-sidebar-productlist-item{
        display: flex;
        flex-direction: column;
        gap: 8px;
        // align-items: center;
        // justify-content: center;
        // border: rgb(var(--accent-color-1)) solid 2px;
        width: 100%;
        height:100%;
        box-sizing: border-box;
        padding: 8px;
        padding-bottom: 16px;
        font-size: 0.8em;
        // user-select: none;

        &.clickable{
            cursor: pointer;
        }

        &:hover{
            // border-radius: 2px;
            // outline: 2px solid rgba(var(--primary-color-1),0.1);  
            // outline-offset: 4px;  
            // text-decoration: underline;
            background: rgba(var(--primary-color-1), 0.02);
        }
        
        .item-image-wrapper{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;
            img{
                height: 100%;
                width: 100%;
                object-position: center center;

                
                transition: opacity 0.2s ease;

                &.isCounter{
                    max-height: 160px;
                    max-width: 160px;
                    scale: 1.8;
                }
            }
        }

        .item-specification-button-container{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);

            display: flex;
            flex-direction: column;
            gap: 4px;

            button{
                pointer-events: none;
                opacity:0;
                transition: opacity 0.2s ease;
                height:32px;
                background: black;
                color: white;
                text-transform: uppercase;

                &:hover{
                    filter: brightness(1.2);
                }

                span.document{
                    // color: rgb(var(--accent-color-1));
                    color:white;
                    background: rgb(var(--accent-color-1));
                    margin-right: 4px;
                    padding: 0 4px;
                }
            }
        }

        .item-description-wrapper{
            // text-align: center;
            box-sizing: border-box;
            padding: 0 6px;
            
            h1{
                font-size: 0.9em;
                margin:0;
                margin-bottom:6px;
                line-height: normal;
            }
            p{
                font-size: 0.6em;
                margin:0;
                line-height: normal;
            }
            .item-description-region-label{
                text-transform: uppercase;
                padding: 2px 6px;
                margin-left: 4px;
                border-radius: 10px;
                background-color: rgb(var(--accent-color-1));
                color:white;
            }
        }

        &:hover{
            img{
                opacity: 0.3;
            }
            button{
                pointer-events: all;
                opacity:1;
            }
        }
    }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>