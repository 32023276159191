<template>
    <div class="ps-gallery">
        <div class="grid">
            <div class="card" v-for="product in products.filter((prod:any) => !prod.isDisabled && prod.locations.length>0)" :key="product" @click="jumpToProduct(product)">
                <div class="ps-product-img-wrapper" :class="{'isCounter':product.name.toLowerCase().includes('counter')}">
                    <img class="ps-product-img" :src="'src/assets/3DViewer/products/'+product.thumbnail" alt="">
                </div>
                <div class="ps-product-info">
                    <div class="ps-product-info-name">{{ product.name }}</div>
                    <div class="ps-product-info-type">{{ product.types.length > 1 ? product.types[1] : product.types[0] }}</div>
                </div>
                <div class="ps-product-hover-wrapper">
                    <!-- <img src="@/assets/icons/footprints_dark.svg" alt="footprints"> -->
                    <button>Show me</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import type { Situation } from "flux-360-logic/dist/src/core/Situation";
import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase } from "@babylonjs/core/Animations/easing";
import { EasingFunction } from "@babylonjs/core/Animations/easing";

import collectionJSON from "@/assets/3DViewer/product-collections.json";

import {Ref,inject} from 'vue';

const panelVisibility:Ref<boolean> = inject('panelVisibility')!;

const props = defineProps({
  products: {
    type: Object,
    required: true
  }
});

function jumpToProduct(product:any){
    let activeSitID = Flux360LogicSingleton.getInstance().getTour().getActiveSituationID();
    let location = product.locations.find((loc:any) => loc.situationID == activeSitID);

    if(location){
        let scene = Flux360LogicSingleton.getInstance().getTour().getSituations().find((situation:Situation) => situation.getID() == activeSitID).getScene();
        let camera = scene.activeCamera;

        var easeINOUT = new CubicEase();
        easeINOUT.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        let fps = 60;
        let duration = 1000;
        Animation.CreateAndStartAnimation("", camera, "alpha", fps, (duration/1000)*fps, camera.alpha, location.alpha, 0, easeINOUT);
        Animation.CreateAndStartAnimation("", camera, "beta", fps, (duration/1000)*fps, camera.beta, location.beta, 0, easeINOUT);
    } else {
        location = product.locations[0]
        Flux360LogicSingleton.getInstance().getTour().changeSituation(location.situationID, {playAnimation:false, alpha: location.alpha, beta: location.beta});
    }

    // Close ProductSearch Panel
    closePanel();

    // Open 3DViewer
    setTimeout(() => {
        var collection = collectionJSON.find(c => c.products.includes(product.name))
        document.dispatchEvent(new CustomEvent("open3DViewer",{detail:{url:collection!.id}}));
    }, 1000);
}

function closePanel(){
    panelVisibility.value = false;
}

</script>

<style scoped lang="scss">
.ps-gallery{
    --card-height: 280px;
    --card-width: 200px;
    --grid-columns: 3;
    --grid-gap: 4px;

    padding-top: var(--bottom-bar-height);
    box-sizing: border-box;
    height:100%;
    width:100%;
    overflow-y: auto;
    
    .grid{
        display:grid;
        grid-template-columns: repeat(var(--grid-columns), var(--card-width));
        gap: var(--grid-gap);
        max-height:100%;
        padding: 0px 4px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.card{
    position: relative;
    height: var(--card-height);
    width: var(--card-width);
    box-sizing: border-box;
    cursor:pointer;
    padding:8px;
    border-radius: var(--radius-1);

    background: rgba(var(--primary-color-1),0);

    &:hover{
        background: rgba(var(--primary-color-1),0.02);
        // background: rgba(white,1);

        .ps-product-img{
            filter: opacity(0.3);
            transform: scale(1.05);
        }

        .ps-product-hover-wrapper{
            opacity:1;
        }
    }

    .ps-product-hover-wrapper{
        position: absolute;
        top:0;
        left:0;
        height: var(--card-width);
        width: var(--card-width);

        display:flex;
        justify-content: center;
        align-items: center;

        opacity:0;
        pointer-events: none;
        transition: opacity 0.1s ease-in-out;

        button{
            border: rgba(var(--primary-color-1), 1) 2px solid;
            background: rgba(var(--primary-color-1), 1);
            color: white;
            height: 32px;
            padding: 0 8px;
            margin: 0;
            line-height: normal;
        }
    }

    .ps-product-img-wrapper{
        width:100%;
        aspect-ratio: 1/1;

        display: flex;
        justify-content: center;
        align-items: center;

        padding-bottom: 8px;
        overflow: hidden;

        .ps-product-img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.2s ease, transform 0.5s ease; 
        }

        &.isCounter{
            .ps-product-img{
                width: 160%;
                height: 160%;
            }
        }
    }

    .ps-product-info{
        .ps-product-info-name{
            text-transform: uppercase;

        }
        .ps-product-info-type{
            opacity:0.4;
        }
    }
}
</style>