import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { WebGPUEngine } from '@babylonjs/core/Engines/webgpuEngine';
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { DefaultLoadingScreen } from "@babylonjs/core/Loading/loadingScreen";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { ArcRotateCameraPointersInput } from "@babylonjs/core/Cameras/Inputs/arcRotateCameraPointersInput";
import { AssetsManager } from "@babylonjs/core/Misc/assetsManager";
import '@babylonjs/core/Materials/Textures/Loaders/envTextureLoader';

import { loadMeshes } from "./loadMeshes";
import { getMedia } from "./ViteUtil";
import { playOpenDoorrAnimation, switchMesh } from "./animations";

export class Viewer3D{

    private canvas:HTMLCanvasElement | undefined;
    private engine:Engine | undefined;
    private scene:Scene | undefined; 
    private assetsManager:AssetsManager | undefined

    public async setup(canvas: HTMLCanvasElement){
        this.canvas = canvas;

        await this.createEngine();
        this.createScene();
        this.createCamera();
        this.createAssetsManager();

        if(process.env.NODE_ENV == "development")
            this.setupDevelopmentMode();
    }

    private async createEngine(){
        console.log("[3D Viewer] Create Engine");

        let engine;
        const webGPUSupported = await WebGPUEngine.IsSupportedAsync;
        if (webGPUSupported && false) {
            console.log("[3D Viwer] Initializing WebGPU engine");
            engine = new WebGPUEngine(this.canvas!, { adaptToDeviceRatio: true, antialias: true });
            await engine.initAsync();
        } else {
            console.log("[3D Viewer] Initializing WebGL engine");
            engine = new Engine(this.canvas!, true, undefined, true); // Fall back to the normal engine if WebGPU is not supported
        }
 
        // Watch for browser/canvas resize events
        window.addEventListener("resize", () => engine!.resize());

        let loadingScreenDiv = document.getElementById("v3-loadingscreen") as HTMLDivElement;
        DefaultLoadingScreen.prototype.displayLoadingUI = () => {
            loadingScreenDiv.style.opacity = "1";
        };
        DefaultLoadingScreen.prototype.hideLoadingUI = () => {
            loadingScreenDiv.style.opacity = "0";
        };

        this.engine = engine; 
        console.log("[3D Viewer] Engine Created");
    }

    private createScene(){
        console.log("[3D Viewer] Create Scene");
        
        let scene = new Scene(this.engine!);
        scene.environmentTexture = CubeTexture.CreateFromPrefilteredData(getMedia("3DViewer/environment.env"), scene);
        scene.environmentTexture.name = "Scene Environment Texture";
        scene.environmentIntensity = 2.5;
        scene.clearColor = new Color4(0, 0, 0, 0);

        this.scene = scene;
        console.log("[3D Viewer] Scene Created");
    }

    private createAssetsManager(){
        console.log("[3D Viewer] Create AssetManager");

        let assetsManager = new AssetsManager(this.scene);
        assetsManager.onTaskErrorObservable.add((task) => {
            console.log('[3D-Viewer] Task failed', task.errorObject.message, task.errorObject.exception);
        });

        let loadingscreenTextDiv = document.getElementById("v3-loadingscreen-text") as HTMLDivElement;
        assetsManager.onProgress = (remainingCount, totalCount) => {
            loadingscreenTextDiv.innerHTML = (100 * (totalCount - remainingCount) / totalCount).toFixed(0) + "%";
        }; 

        this.assetsManager = assetsManager;
        console.log("[3D Viewer] AssetManager Created");
    }

    private createCamera(){
        console.log("[3D Viewer] Create Camera");

        let camera = new ArcRotateCamera("MainCamera", Math.PI / 2, Math.PI / 2, 250, new Vector3(0, 100, 0), this.scene); 
        camera.attachControl(this.canvas, true, false);
        (camera.inputs.attached.pointers as ArcRotateCameraPointersInput).buttons = [0]; // Remove Right click Pan
        // TODO: Right Click = Rotate Camera
        camera.fov = 1;
        camera.minZ = 1;
        camera.maxZ = 800;

        console.log("[3D Viewer] Camera Created");
    }

    public startRenderLoop(){
        console.log("[3D Viewer] Start Render Loop");

        this.engine!.runRenderLoop(() => {
			this.scene!.render();
        });
    }

    public async loadMeshes(productData:any){
        await loadMeshes(this, productData);
    }

    public playOpenDoorrAnimation(activeProductIndex:number, isDoorOpen:boolean){
        playOpenDoorrAnimation(this.scene!, activeProductIndex, isDoorOpen);
    }

    public async switchMesh(products:any[], oldIndex:number, newIndex:number){
        await switchMesh(this.scene!, products, oldIndex, newIndex);
    }

    public cleanUp(){
        console.log("[3D Viewer] Cleanup 3D Viewer");

        // Reset Loadingscreen
        let loadingScreenDiv = document.getElementById("v3-loadingscreen") as HTMLDivElement;
        loadingScreenDiv.style.opacity = "1";
        let loadingScreenText = document.getElementById("v3-loadingscreen-text") as HTMLDivElement;
        loadingScreenText.innerHTML = "0%";

        // Stop Rendering
        this.engine!.stopRenderLoop();

        // Remove Meshes, Textures and Materials
        let scene = this.scene!;

        // Remove Meshes
        for (let i = 0; i < scene.meshes.length; i++) 
            if (scene.meshes[i])
                scene.meshes[i].dispose();
        scene.meshes = [];

        // Remove Animation Groups
        for (let i = 0; i < scene.animationGroups.length; i++) 
            if (scene.animationGroups[i])
                scene.animationGroups[i].dispose();
        scene.animationGroups = [];

        // Remove Materials
        for (let i = 0; i < scene.materials.length; i++) 
            if (scene.materials[i])
                scene.materials[i].dispose();
        scene.materials = [];

        // Remove Transform Nodes
        for (let i = 0; i < scene.transformNodes.length; i++) 
            if (scene.transformNodes[i])
                scene.transformNodes[i].dispose();
        scene.transformNodes = [];

        // Remove Textures //TODO: Fix invisible objects
        for (let i = 0; i < scene.textures.length; i++) 
            if (scene.textures[i] && !scene.textures[i].name.includes("Environment"))
                scene.textures[i].dispose();
    }

    public destroy(){
        console.log("[3D Viewer] Destroed");
        this.scene!.dispose();
        this.engine!.dispose(); 
    }

    ////////////
    // Getter //
    ////////////
    public getScene(){
        return this.scene!;
    }
    public getEngine(){
        return this.engine!;
    }
    public getAssetsManager(){
        return this.assetsManager!;
    }

    ///////////
    // DEBUG //
    ///////////
    private setupDevelopmentMode(){
        this.createDebugButton();
    }

    private async createDebugButton(){
        let div = document.createElement("div");
        div.innerHTML = "Debug";
        div.style.background = "gray";
        div.style.padding = "10px";
        div.style.cursor = "pointer";
        div.style.position = "absolute";
        div.style.top = "24px";
        div.style.right = "24px";
        div.style.color = "white";
        div.style.fontFamily = "Arial";
        div.addEventListener("click", async () => {
            // Import Inspector modules!
            await import('@babylonjs/inspector');
            await import("@babylonjs/core/Debug/debugLayer");

            this.scene!.debugLayer.isVisible() ? this.scene!.debugLayer.hide() : this.scene!.debugLayer.show();
        });

        let parent = document.getElementsByClassName("Viewer3D")[0];
        parent.getElementsByTagName('canvas')[0].parentElement?.appendChild(div);
    }
}