import { HoshizakiAnalytics } from "./HoshizakiAnalytics";

export class HoshizakiAnalyticsSingelton {

    private static instance: HoshizakiAnalytics;

    public static getInstance(): HoshizakiAnalytics {
        return this.instance;
    }

    public static setInstance(instance:HoshizakiAnalytics) {  
        this.instance = instance;
    }
}