<template>
	<div class="CookieBanner" v-if="isVisible">
		<div class="cb-text">
			<div>We are using cookies to give you the best experience on our website.</div>
			<div style="display: inline-block;">You can find out more about which cookies we are using</div>
			<div @click="openLink()" class="cb-link">here</div>
		</div>
		<div class="cb-button cb-reject" @click="rejectCookies()">Reject</div>
		<div class="cb-button cb-accept" @click="acceptCookies()">Accept</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { HoshizakiAnalyticsSingelton } from '@/analytics/AnalyticsSingelton';

let isVisible = ref(true);

let acceptCookies = () => {
    localStorage.setItem("ShowroomAnalyticsEnabled", "true"); // Set Localstore that cookies are accepted
    HoshizakiAnalyticsSingelton.getInstance().cookieConsent();
    isVisible.value = false;
}

let rejectCookies = () => {
    localStorage.setItem("ShowroomAnalyticsEnabled", "false"); // Set Localstore that cookies are rejected
    isVisible.value = false;
}

let openLink = () => {
    window.open("https://hoshizaki-europe.com/privacy-policy/", '_blank')
}

onMounted(() => {
    // Check localstore if cookies already accepted/rejected and hide cookie banner if so
    let cookiesEnabled = localStorage.getItem("ShowroomAnalyticsEnabled");
    if(cookiesEnabled)
        isVisible.value = false;
})
</script>

<style scoped>
.CookieBanner{
	width: 100%;
	position: absolute;
	z-index: 20;
	bottom: 0;
	background-color: #202020;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	padding: 25px 0px;
	border-top: 2px solid white;
}
.cb-text{
	font-size: 18px;
    line-height: 18px;
	white-space: wrap;
	margin-right: 50px;
}
.cb-button{
	padding: 10px 20px;
	cursor: pointer;
	outline: 2px solid white;
	transition: .3s background-color ease, .3s color ease;
	margin-right: 20px;
	font-size: 16px;
	min-width: 90px;
	text-align: center;
}
.cb-button:hover{
	background-color: rgb(209, 209, 209);
	outline: 2px solid white;
	color: #1d9dd8;
}
.cb-accept{
	background-color: #1d9dd8;
	outline-color: #1d9dd8;
}
.cb-link{
	cursor: pointer;
	text-decoration: underline;
	display: inline-block;
	margin-left: 4px;
}
</style>