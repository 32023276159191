declare const dataLayer: any[];

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

function gtag(..._:any){ dataLayer.push(arguments); }

export class Analytics {

    public setup(gtagID:string) {
        window.dataLayer = window.dataLayer || [];

        // Set default gtag consent to denied
        gtag('consent', 'default', {
            'ad_storage': 'granted', // denied
            'ad_user_data': 'granted', // denied
            'ad_personalization': 'granted', // denied
            'analytics_storage': 'granted' // denied
        });

        // Load Google Analytics Script
        var gtagScript = document.createElement('script');
        gtagScript.setAttribute('src','https://www.googletagmanager.com/gtag/js?id=' + gtagID)
        document.head.appendChild(gtagScript);

        gtag('js', new Date());
        gtag('config', gtagID);
    } 

    public cookieConsent(){
        console.log("Cookies Accepted!");
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
        });
    }

    // send data tp GA4
    public sendEvent(eventName:string, eventData:any) {
        console.log("GA4 event " + eventName, eventData);
        gtag("event", eventName, eventData);
    }
}