<template>
    <div id="ProductSearch" :class="{'focus':isVisible}">
        <div class="ps-background" v-if="isVisible" @click="isVisible = !isVisible"/>
        <div class="ps-container" v-if="isVisible">
            <!-- Header -->
            <div class="ps-header">
                <div class="title">Productsearch</div>
                <button class="close icon-s" @click="isVisible = !isVisible"></button>
            </div>

            <!-- Body -->
            <div class="ps-body">
                <div class="ps-sidepanel">
                    <h4>Categories</h4>
                    <button :class="{'active': filterTag == 'all'}" @click="filterProducts('all')">All</button>
                    <button v-for="category in categories" :key="category" :class="{'active': filterTag == category}" @click="filterProducts(category)">{{category}}</button>
                </div>
                <ProductGallery :products="filteredProducts"/>
            </div>
        </div>
        
        <Teleport to="#Menubar .menubar-functions-slot">
            <button id="button-product-search" class="tertiary icon-m" :class="{ 'active': isVisible }" @click="isVisible = !isVisible">
                <div class="tooltip">Product Search</div>
                <img v-if="isVisible" src="@/assets/icons/search_white.svg" alt=""/>
                <img v-else src="@/assets/icons/search_cyan.svg" alt=""/>
            </button>
        </Teleport>
    </div>

</template>

<script setup lang="ts">
import ProductGallery from './components/ProductGallery.vue';

import collectionJSON from "@/assets/3DViewer/product-collections.json";
import productJSON from "@/assets/3DViewer/products.json";

import {ref,Ref,onMounted,provide} from 'vue';

const isVisible = ref(false);
provide('panelVisibility',isVisible);

const filterTag:Ref<string> = ref('')
const filteredProducts:Ref<any[]> = ref([]);
const categories:Ref<string[]> = ref([]);

productJSON.forEach(product => {
    if(!categories.value.includes(product.types[0]) && product.locations.length > 0){
        categories.value.push(product.types[0])
    }
})
function filterProducts(tag:string){
    filterTag.value = tag;
    productJSON.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        // names must be equal
        return 0;
    })
    if(tag != "all"){
        filteredProducts.value = productJSON.filter((product) => product.types[0] == tag && product.locations.length > 0);
    } else {
        filteredProducts.value = productJSON;
    }
}

onMounted(()=>{
    filterProducts("all");
})
</script>

<style scoped lang="scss">
#ProductSearch{
    position:absolute;
    inset:0;
    padding-bottom: calc(var(--bottom-bar-height) * 1.8);
    padding-top: calc(var(--bottom-bar-height));
    
    display:flex;
    justify-content: center;
    align-items: center;
    
    pointer-events: none;

    &.focus{
        z-index:1;   
    }
}
.ps-background{
    position: absolute;
    inset:0;
    background: rgba(var(--primary-color-1),.6);
    pointer-events: all;
}

.ps-container{
    position: relative;
    height:100%;
    background: white;
    pointer-events: all;

    display: flex;
    flex-direction: column;

    .ps-header{
        position: absolute;
        top:0;
        right:0;
        left:0;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        // background: rgb(var(--secondary-color-1));

        .title{
            padding-left: 24px;
            padding-right: 24px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
    .ps-body{
        height:100%;
        display:flex;
        flex-direction: row;

        .ps-sidepanel{
            display:flex;
            flex-direction: column;
            width: 200px;
            height:100%;
            padding-top: var(--bottom-bar-height);

            text-transform: uppercase;

            h4{
                padding-left: 48px;
            }

            button{
                justify-content: flex-start;
                text-transform: uppercase;
                text-align: left;
                background: transparent;
                &.active{
                    color: rgb(var(--accent-color-1));
                }
            }
        }
    }
}
</style>